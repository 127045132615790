.main-div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: calc(100vh - 80px);
    overflow-y: auto;
    overflow-x: hidden;
}



.main-div::-webkit-scrollbar{
    display: none;
}

.main-content-div{
    display: flex;
}

.footer-div{
    width: 100vw;
    height: fit-content;
}

.map-div{
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100vw;
    align-items: center;
    justify-content: center;
    height: fit-content;
    padding: 15px 20px;
    background-color: #5887b5;
    color: white;
}

.gradient{
    position: absolute;
    top:-5px;
    left: 0;
    z-index: 2;
    width: 100vw;
    transform: rotate(180deg);
    background: linear-gradient(transparent, #f3f3f2 98%)
}

.contact-div{
    display: flex;
    justify-content: space-between;
}

.footer-menu-each{
    text-align: center;
    margin-right: 30px;
}

.footer-menu-each:last-child{
    margin-right: 20px;
}

.footer-menu-each a{
    font-size: 14px;
    font-weight: bold;
    color: #2c507b;
    text-decoration: none;
}

.category-name{
    color: #2c507b;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
}

.category-name a{
    margin-right: 10px;
}

.undercategory-main{
    width: fit-content;
    position: absolute;
    display: flex;
    border: 2px solid #2c507b;
    border-top: none;
    border-right: none;
    padding: 0 10px;
    animation: openMenu ease-in-out 0.2s;
}

@keyframes openMenu{
    0%{
        opacity: 0;
    }
    50%{
        opacity: 0.3;
    }
    90%{
        opacity: 1;
    }
}

.undercategory-div{
    margin-right: 20px;
    transform: scale(1);
    transition: transform ease-in 0.2s;
}

.undercategory-div a{
    font-size: 14px;
    color: #2c507b;
    text-decoration: none;
    font-weight: normal;
}

.undercategory-div:hover{
    cursor: pointer;
    transform: scale(1.1);
    transition: transform ease-in 0.2s;
}

.social-media-full{
    display: flex;
}

.social-media{
    display: flex;
    align-items: center;
    margin-right: 30px;
}

.social-media img{
    width: 20px;
    margin-right: 10px ;
}

.social-media a{
    color: white;
}

.copyright-div{
    text-align: center;
    height: fit-content;
    padding: 10px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.596);
    background-color: #1d3b5f;
}

.contacts{
    display: flex;
    align-items: center;
}

.contact-part-icon{
    font-size: 22px;
    color: white
}

.contacts p{
    margin-right: 10px;
}

@media (max-width: 600px){
    .main-div{
        height: calc(100vh - 80px);
    }

    .footer-menu-each{
        width: calc((100vw - 60px)/2);
        margin-right: 10px;
    }

    .map-div{
        width: 100vw;
        flex-wrap: wrap;
    }
}

