.header-div{
    display: flex;
    justify-content: space-between;
    width: 100vw;
    background-color: rgba(196,196,190, 0.2);
}

.header-div-main {
    display: flex;
    justify-content: space-between;
    width: 100vw;
    height: 60px;
    padding: 10px 0;
    background-color: rgba(196,196,190, 0.2);
    box-shadow: 0px 5px 5px rgba(168, 167, 167, 0.692);
}

.menu{
    background-color: rgba(246, 247, 247, 0.137);
    box-shadow: 0px 5px 5px rgba(168, 167, 167, 0.692);
    border-top: 1px solid rgba(128, 128, 128, 0.185);
    border-bottom: 1px solid rgba(128, 128, 128, 0.349);
}

.name-container{
    width: 100vw;
    display: flex;
    justify-content: space-between;
    background-color: white;
    padding: 15px;
}

.logo-div{
    display: flex;
    align-items: center;
    cursor: pointer;
    max-width: 50%;
}

.logo-image{
    width: 100px;
    height: 100px;
    margin-right: 10px;
    margin-left: 15px;
}

.logo-image-main{
    width: 60;
    height: 60;
    margin-right: 10px;
    margin-left: 15px;
}

.name-div{
    display: block;
    text-align: center;
    align-self: center;
}

.name-h{
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-family: Serif;
    color: #2c507b;
}

.name-container h1{
    margin: 0;
    font-size: 25px;
}

.search-lang-end{
    display: flex;
    align-items: end;
}

.search-lang-div{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 600px){
    .header-div{
        height: 80px;
    }

    .logo-image{
        min-width: 50px;
        max-width: 50px;
        height: 50px;
    }

    .name-div{
        display: none;
    }
}
