.home-div{
    position: relative;
    display: flex;
    width: 100vw;
    overflow: hidden;
    margin-bottom: 20px
}


.animation{
    width: 100vw;
}

.menu-on-animation{
    display: flex;
    justify-content: start;
    padding: 10px;
    background-color: rgb(248, 245, 245);
    color: #2c507b;

}

.search-lang{
    /* position: absolute; */
    display: flex;
    align-items: center;
    /* z-index: 10; */
    /* bottom: 20px; */
    /* right: 20px; */
}

.name-h{
    display: flex;
    color: #2c507b;
    padding: 10px 0 20px 30px ;
}

.name-container{
    width: 100vw;
    display: flex;
    justify-content: space-between;
    background-color: white;
}

.slogan{
    position: absolute;
    top:0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 70vw;
    margin-left: 15vw;
    text-align: center;
    font-size: 50px;
    color: white;
    font-weight: 700;
}

.name-h div{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.name-h img{
    width: 100px;
    margin-right: 10px;
}

.name-h h1{
    margin: 0;
    font-size: 32px;
    font-weight: 400;
    width: fit-content;
    text-align: start;
}

.home-info-div-scroll{
    width: 100vw;
}


@keyframes infoslide{
    0%{
        transform: translateX(0);
    }
    30%{
        transform: translateX(0);
    }
    32%{
        transform: translateX(-100vw)
    }
    62%{
        transform: translateX(-100vw);
    }
    65%{
        transform: translateX(-200vw);
    }
    98%{
        transform: translateX(-200vw)
    }
    100%{
        transform: translateX(-300vw)
    }


}

.home-info-div{
    display: flex;
    width: fit-content;
    animation: infoslide ease-in-out 27s infinite;
}

.home-info-div:hover{
    animation-play-state: paused;
}

.home-info{
    display: flex;
    width: 90vw;
    margin: 0 5vw;
    margin-bottom: 25px;
    border-radius: 20px;
}

.bgblue{
    background-color: #2c507b;
}

.bggray{
    background-color: gray;
}

.bgdarkblue{
    background-color: rgb(22, 35, 53);
}

.home-info-image{
    width: 50%;
    height: calc(45vw / 1.55);
    overflow: hidden;
}

.home-info img{
    display: block;
    width: 45vw;
}

.home-info-image-from-right{
    border-radius: 0 20px 20px 0;
}

.home-info-text{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: justify;
    width: 50%;
    color: white;
    font-size: 20px;
    font-weight: 500;

}

.home-info-text div{
    margin: 40px;
}

.home-info-image-from-left{
    border-radius: 20px 0 0 20px;
}